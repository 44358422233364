import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import _ from "lodash";
import { useRef } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  dataChart?: any;
  labels?: string[];
  displayLengend?: boolean;
  plugins?: [any];
  keyChart?: number;
}
export default function LineChart(props: IProps) {
  //! state
  const chartRef = useRef();
  const { dataChart, labels, displayLengend = true, plugins, keyChart } = props;

  const options = {
    type: "line",
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        fill: false,
      },
    },

    hover: {
      mode: "nearest",
      intersect: false,
    },
    interaction: {
      intersect: false,
    },

    plugins: {
      legend: {
        display: displayLengend,
        position: "bottom" as any,
        labels: {
          color: "black",
          boxHeight: 2,
          boxWidth: 30,
        },
      },
    },

    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    // },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw: function (chart: any, args: any, options: any) {
      const ctx = chart.ctx;
      ctx.save();
      ctx.textAlign = "center";
      ctx.fillStyle = "grey";
      ctx.font = "bold 30px Inter";
      ctx.fillText(`11`, chart.width / 2, chart.height / 2);
      ctx.restore();
    },
  };

  const data = {
    labels: labels ?? [],
    datasets: dataChart,
  };
  //! function

  //! render
  return (
    <div
      className="h-full w-full relative"
      style={{
        minHeight: "250px",
      }}
    >
      <Line
        key={keyChart}
        ref={chartRef}
        updateMode="resize"
        options={options as any}
        data={data}
        // plugins={plugins}
      />
    </div>
  );
}
