import { useParams } from "react-router-dom";
import { CommonStyles } from "../../components/ui";
import CommonIcons from "../../assets/icons";
import useGetDeviceByID from "../../modules/device/hooks/useGetInfoDeviceByID";
import { DatePicker, Typography } from "antd";
import useGetAttributeKey from "../../modules/telemetry/hooks/useGetAttributeKey";
import { functionRole, parseQueryString } from "../../helpers";
import ChartLMMN from "../../components/detailStation/Chart/ChartLMMN/ChartLMMN";
import { IDevice } from "../../modules/device/device.interface";
import { useCallback } from "react";
import { CDRC, LMMN, filterDateChartRealTime } from "../../constants/Index";
import moment from "moment";
import { TOption } from "../../interface";

import useFilterDate from "../../hooks/useFilterDate";
import ChartWaterLevel from "../../components/detailStation/Chart/ChartWaterLevel";
import ChartCrackMetter from "../../components/detailStation/Chart/ChartCrackMetter";
import ChartVectorGal from "../../components/detailStation/Chart/ChartVectorGal";
import TableLMMN from "../../components/detailStation/Table/TableLMMN";
import Alarm from "../../components/detailStation/Alarm";
import useToggle from "../../hooks/useToggle";
import baseRouter from "../../router/baseRouter";
import ModalTicket from "../../components/overall/modalTicket";
import { useAppSelector } from "../../hooks/hooks";
import { ResponseUI } from "../../constants/response";
export default function DetailStation() {
  //! state
  const user = useAppSelector((state) => state.user.user);
  const params = useParams();
  const idStation = String(params.id);
  const { open, shouldRender, toggle } = useToggle();
  const { data: detailDevice } = useGetDeviceByID(idStation);

  const { data: dataPosition } = useGetAttributeKey(
    String(detailDevice?.data.id.entityType),
    idStation,
    !!detailDevice?.data.id.entityType,
    parseQueryString({
      keys: "lat,lon,address",
    })
  );
  const lat = dataPosition?.data.find((item) => item.key === "lat");
  const lon = dataPosition?.data.find((item) => item.key === "lon");
  const address = dataPosition?.data.find((item) => item.key === "address");

  const stationType =
    detailDevice?.data.type === LMMN
      ? "Trạm cảnh báo lũ quét"
      : "Trạm cảnh báo sạt lở";

  const station = detailDevice?.data.name.split("/");
  //! function
  const generateOptionsDate = useCallback(() => {
    let options = filterDateChartRealTime;

    const currentMonth = moment.utc().month() + 1;
    const item: TOption[] = [
      {
        label: `Tháng ${currentMonth - 1}`,
        value: currentMonth - 2,
      },
      {
        label: `Tháng ${currentMonth - 2}`,
        value: currentMonth - 3,
      },
      {
        label: `Tháng ${currentMonth - 3}`,
        value: currentMonth - 4,
      },
      {
        label: "Tuỳ chỉnh",
        value: "setting",
      },
    ];
    options = [...options, ...item];

    return options;
  }, []);
  const { dateFilter, dateSetting, onChangeFilterDate, onChangeSettingDate } =
    useFilterDate(generateOptionsDate());
  const handleCreateTicket = () => {
    toggle();
  };
  //! render
  const renderExtraAction = () => {
    return (
      <div className="flex gap-3">
        <Alarm idStation={String(idStation)} />
        <CommonStyles.ButtonUI
          // size="large"
          Icons={<CommonIcons.PlusCircleFilled />}
          onClick={handleCreateTicket}
          className={ResponseUI.button}
        >
          Tạo ticket
        </CommonStyles.ButtonUI>
      </div>
    );
  };
  const renderStatusStation = () => {
    const status = detailDevice?.data.active ? "Đang kết nối" : "Mất kết nối";
    const color = detailDevice?.data.active ? "bg-[#52D887]" : "bg-[#F96161]";
    const textColor = detailDevice?.data.active
      ? "text-[#52D887]"
      : "text-[#F96161]";

    return (
      <div className="p-3 bg-white rounded-xl">
        <div className="relative rounded-lg bg-main-color h-full p-4">
          <div className="absolute right-3 top-3 bg-white border rounded-2xl">
            <div className="flex items-center gap-2 mx-3">
              <span
                className={`w-2 h-2 border rounded-full border-none ${color}`}
              ></span>
              <p className={`${textColor}`}>{status}</p>
            </div>
          </div>

          <div className="flex flex-col gap-3 mt-5">
            <div className="flex justify-center items-center bg-white h-12 w-12 rounded-md">
              <CommonIcons.Enviroment
                className="text-main-color"
                style={{
                  fontSize: "22px",
                  color: "rgb(47 128 237 / var(--tw-text-opacity))",
                }}
                color="bg-main-color"
              />
            </div>

            <div className="flex gap-5">
              <div className="border-r pr-8 border-gray-400">
                <Typography
                  className={`text-white text-base ${ResponseUI.text}`}
                >
                  Mã trạm
                </Typography>
                <Typography.Title level={3} className="!text-white !my-1">
                  {String(station && station[0])}
                </Typography.Title>
              </div>

              <div>
                <Typography className="text-white text-base">
                  Tên trạm
                </Typography>
                <Typography.Title level={3} className="!text-white !my-1">
                  {String(station && station[1])}
                </Typography.Title>
              </div>
            </div>

            <div className="flex gap-3">
              <Typography className="text-white text-base border-r border-gray-400 pr-3">
                Loại: {stationType}
              </Typography>
              <Typography className="text-white text-base border-r border-gray-400 pr-3">
                Kinh độ: {lon?.value}
              </Typography>
              <Typography className="text-white text-base border-r border-gray-400 pr-3">
                Vĩ độ: {lat?.value}
              </Typography>
              <Typography className="text-white text-base">
                Vị trí: {address?.value}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const extraFilterChart = () => {
    const format = "DD/MM";

    const start = moment.utc(dateFilter.startDate).format(format);
    const end = moment.utc(dateFilter.endDate).format(`${format}/YYYY`);

    return (
      <div>
        <p className="font-medium">{`${start} - ${end}`}</p>
        <CommonStyles.SelectUI
          value={dateFilter.value}
          options={generateOptionsDate()}
          onChange={onChangeFilterDate}
        />
        {dateSetting && (
          <DatePicker.RangePicker
            allowClear={false}
            onChange={onChangeSettingDate}
          />
        )}
      </div>
    );
  };

  const renderTypeStation = () => {
    if (detailDevice?.data.type === LMMN) {
      return (
        <>
          <ChartLMMN
            dateFilter={dateFilter}
            detailDevice={detailDevice?.data as IDevice}
          />
          <ChartWaterLevel
            dateFilter={dateFilter}
            detailDevice={detailDevice?.data as IDevice}
          />
        </>
      );
    }

    if (detailDevice?.data.type === CDRC) {
      return (
        <>
          <ChartCrackMetter
            dateFilter={dateFilter}
            detailDevice={detailDevice?.data as IDevice}
          />
          <ChartVectorGal
            dateFilter={dateFilter}
            detailDevice={detailDevice?.data as IDevice}
          />
        </>
      );
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <CommonStyles.TitleCardUI
        title="Chi tiết trạm"
        subTitle={[
          {
            title: stationType,
            link:
              detailDevice?.data.type === LMMN
                ? `${baseRouter.stationLMMN}`
                : `${baseRouter.stationCDRC}`,
          },
          {
            title: String(station && station[1]),
            link: `${baseRouter.station}/${params.id}`,
          },
        ]}
        extra={
          (functionRole(String(user?.lastName)) === 1 ||
            functionRole(String(user?.lastName)) === 2) &&
          renderExtraAction()
        }
      />
      {renderStatusStation()}

      <CommonStyles.CardResize
        title="Biểu đồ báo cáo toàn hệ thống"
        Icon={CommonIcons.LineChart}
        extra={extraFilterChart()}
      >
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-5">
          {renderTypeStation()}
        </div>
      </CommonStyles.CardResize>

      <TableLMMN detailDevice={detailDevice?.data as IDevice} />

      {shouldRender && (
        <CommonStyles.ModalUI hiddenAction open={open} toggle={toggle}>
          <ModalTicket
            idStation={String(idStation)}
            toggle={toggle}
            onlyCreate={true}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
