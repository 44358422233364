import { useCallback, useEffect, useState } from "react";
import { getTimeStampHour, parseQueryString } from "../../../../helpers";
import { IDateFilter } from "../../../../interface/IDateFilter";
import { IDevice } from "../../../../modules/device/device.interface";
import { CommonStyles } from "../../../ui";
import {
  IDataTime,
  getDataFilterDate,
  valueIncreaseByFilterDate,
} from "../../../../helpers/filterDate";
import { useGetLabelsChart } from "../../../../hooks/useGetLabelChart";
import _ from "lodash";
import { ENTITY_TYPE } from "../../../../constants/Index";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { IDataChart } from "../../../../interface";
import RealChartWaterLevel from "./RealChartWaterLevel";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { colorHighLow } from "../../../../constants/color";
import { segment } from "../../../../helpers/chart";
import moment from "moment";

interface IProps {
  detailDevice: IDevice;
  dateFilter: IDateFilter;
}
export default function ChartLMMN(props: IProps) {
  //! state
  const { detailDevice, dateFilter } = props;
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState<IDataChart>();
  const { data: dataCrack } = useGetAttributeKey(
    ENTITY_TYPE,
    detailDevice.id.id,
    true,
    parseQueryString({ keys: "colorChart" })
  );
  const { labelChart } = useGetLabelsChart(
    dataChart?.dataChart,
    dateFilter,
    loading,
    dataChart?.dataChart && dataChart?.dataChart.length > 0
  );
  //! function
  const generateDataChart = useCallback(() => {
    if (!!dataChart?.dataChart && dataChart.dataChart.length > 0) {
      const dataLine = dataChart?.dataChart.map((child) => {
        return child.data["Water Main Level"].status
          ? child.data["Water Main Level"].value
          : NaN;
      });
      return dataLine;
    } else {
      return [{}];
    }
  }, [dataChart, dateFilter]);

  useEffect(() => {
    const dataDevice = async () => {
      const increaseTime = valueIncreaseByFilterDate(dateFilter);
      setLoading(true);
      const dataLow_High = telemetryService.getAttributeKey(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({ keys: "Sethigh" })
      );
      // const dataStart = await telemetryService.getTimeSeries(
      //   detailDevice?.id?.entityType,
      //   detailDevice?.id?.id,
      //   parseQueryString({
      //     startTs: dateFilter.startDate,
      //     endTs: dateFilter.endDate,
      //     limit: 1,
      //     useStrictDataTypes: true,
      //     keys: "data",
      //     orderBy: "ASC",
      //   })
      // );
      let startTime = dateFilter.startDate;
      let dataElement: IDataTime[] = [];

      let check = true;
      if (dateFilter.type === "second") {
        const detailStation = await telemetryService.getTimeSeries(
          detailDevice?.id?.entityType,
          detailDevice?.id?.id,
          parseQueryString({
            startTs: dateFilter.startDate,
            endTs: dateFilter.endDate,
            limit: 50000,
            useStrictDataTypes: true,
            keys: "data",
            orderBy: "DESC",
          })
        );
        if (detailStation?.data?.data) {
          detailStation?.data?.data.map((item: any, index: any) => {
            const dataOrder = getDataFilterDate(
              moment(item.ts).valueOf(),
              item.value
            );
            dataElement.push(dataOrder);
          });
        }
      } else {
        while (startTime <= dateFilter.endDate && check) {
          if (startTime > dateFilter.endDate) {
            startTime = dateFilter.endDate;
            check = false;
          }
          const start = getTimeStampHour(startTime, "start", dateFilter);
          const end = getTimeStampHour(startTime, "end", dateFilter);
          const detailStation = await telemetryService.getTimeSeries(
            detailDevice?.id?.entityType,
            detailDevice?.id?.id,
            parseQueryString({
              startTs: start,
              endTs: end,
              limit: 1,
              useStrictDataTypes: true,
              keys: "data",
              orderBy: "DESC",
            })
          );
          if (detailStation?.data?.data) {
            const dataOrder = getDataFilterDate(
              start,
              detailStation?.data?.data[0]?.value
            );
            dataElement.push(dataOrder);
          }

          startTime += increaseTime;
        }
      }

      setDataChart({
        station: detailDevice.name,
        dataChart: dataElement,
        dataLow_High: (await dataLow_High).data,
      });
      setLoading(false);
    };

    if (detailDevice && dateFilter.value !== "real") {
      dataDevice();
    }
  }, [detailDevice, dateFilter]);
  //! render
  const renderBoxReport = (dataChart: IDataTime[], keyFilter: string) => {
    const filteredData = dataChart?.filter(
      (item) => item?.data[keyFilter]?.status !== 0
    );
    const minValueObject = _.minBy(filteredData, `data[${keyFilter}].value`);
    const valueMin = Number(
      _.get(minValueObject, `data[${keyFilter}].value`, null)
    );
    const maxValueObject = _.maxBy(filteredData, `data[${keyFilter}].value`);
    const valueMax = Number(
      _.get(maxValueObject, `data[${keyFilter}].value`, null)
    );
    return (
      <CommonStyles.CardResize
        title={"Thống kê mực nước"}
        className=" border border-gray-200 pb-0"
      >
        <div className="grid grid-cols-2 gap-4 pt-5">
          <CommonStyles.BoxInfo
            title="Mực nước cao nhất"
            value={valueMax}
            unit={"cm"}
          />
          <CommonStyles.BoxInfo
            title="Mực nước thấp nhất"
            value={valueMin}
            unit={"cm"}
          />
        </div>
      </CommonStyles.CardResize>
    );
  };
  const renderChart = (title: string) => {
    const keyFilter = "Water Main Level";
    if (dateFilter.value === "real") {
      return (
        <RealChartWaterLevel
          loading={loading}
          dateFilter={dateFilter}
          detailDevice={detailDevice}
          title={title}
          renderBoxReport={renderBoxReport}
        />
      );
    } else {
      return (
        <div className="flex flex-col gap-4">
          <CommonStyles.CardResize
            title={title}
            className=" border border-gray-200"
          >
            <CommonStyles.LineChart
              labels={labelChart ?? []}
              dataChart={[
                {
                  borderColor: dataCrack?.data?.find(
                    (el) => el.key === "colorChart"
                  )?.value,
                  data: generateDataChart() ?? [],
                  cubicInterpolationMode: "monotone",
                  lineTension: 3,
                  borderWidth: 2.5,
                  segment: segment,
                  spanGaps: true,
                },
                // {
                //   label: `Ngưỡng cao ${dataChart?.station?.split("/")[1]}`,
                //   data: Array.from(
                //     { length: generateDataChart()?.length },
                //     () =>
                //       Number(
                //         dataChart?.dataLow_High &&
                //           dataChart?.dataLow_High[0]?.value?.split(",")[4]
                //       )
                //   ),
                //   borderColor: colorHighLow.value,
                //   lineTension: 0,
                //   borderWidth: 1.5,
                //   borderDash: [5, 5],
                //   order: 0,
                // },
              ]}
              displayLengend={false}
            />
          </CommonStyles.CardResize>
          {renderBoxReport(dataChart?.dataChart as IDataTime[], keyFilter)}
        </div>
      );
    }
  };
  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="h-[400px]">
          <CommonStyles.LoadingUI />
        </div>
      ) : (
        renderChart("Độ sâu mực nước (cm)")
      )}
    </div>
  );
}
