import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { CommonStyles } from "../../../ui";
import { TOption } from "../../../../interface";
import Regex from "../../../../constants/regex";

interface IProps {
  idStation: string;
}
const optionsTime: TOption[] = [
  {
    label: "Phút",
    value: 1,
  },
  {
    label: "Giờ",
    value: 60,
  },
  {
    label: "Ngày",
    value: 1440,
  },
];
export default function TimeSendData(props: IProps) {
  //! state
  const { idStation } = props;
  const dispatch = useAppDispatch();
  const [time, setTime] = useState(1);
  const [optionSelected, setOptionSelected] = useState(1);
  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "Settimeupload" })
  );
  useEffect(() => {
    if (data?.data) {
      setTime(Number(data.data[0]?.value ?? 0));
    }
  }, [isLoading]);
  //! function
  const handleCheck = async () => {
    if (data?.data && Number(data.data[0]?.value) == time) {
      return;
    }
    try {
      const postSettime = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Settimeupload: time * optionSelected,
        }
      );
      if (postSettime.status === 200) {
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Regex.number.test(e.target.value)) {
      setTime(Number(e.target.value));
    } else {
      setTime(1);
    }
  };
  const hanldeChangeOption = (value: number) => {
    setOptionSelected(value);
  };

  //! render
  return (
    <CommonStyles.SubCard
      title="Thời gian truyền dữ liệu"
      className="justify-between pb-9"
    >
      <CommonStyles.InputUI
        defaultValue={time}
        onChange={handleChange}
        onBlur={handleCheck}
        addonAfter={
          <CommonStyles.SelectUI
            value={optionSelected}
            onChange={hanldeChangeOption}
            options={optionsTime}
            className=" md:!min-w-20"
          />
        }
        className="border-0"
      />
    </CommonStyles.SubCard>
  );
}
