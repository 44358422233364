import moment from "moment";
import { IDataTime } from "../../../interface";

interface IProps {
  lastestValueRealtime?: IDataTime;
  typeValue:
    | "Hourly rainfall"
    | "Water Main Level"
    | "CrackMetter"
    | "Vector Gal_Max";
}
const ExtraValueRealtime = (props: IProps) => {
  //! state
  const { lastestValueRealtime, typeValue } = props;
  //! function
  const checkTypeValue = () => {
    switch (typeValue) {
      case "Hourly rainfall":
        return "Lượng mưa";
      case "Water Main Level":
        return "Mực nước";
      case "CrackMetter":
        return "Căng kế";
      case "Vector Gal_Max":
        return "Rung chấn";
      default:
        return "";
    }
  };

  if (!lastestValueRealtime) {
    return null;
  }
  //! render
  return (
    <div>
      <div className="flex gap-2 justify-end text-gray-500">
        <span>
          {lastestValueRealtime?.hour}:{lastestValueRealtime?.minute}
        </span>
        <span>
          {moment(lastestValueRealtime?.data.time).format("DD/MM/YYYY")}
        </span>
      </div>

      {
        <div className="font-normal">
          {checkTypeValue()} gần nhất:
          {` ${lastestValueRealtime?.data[`${typeValue}`]?.value}${
            lastestValueRealtime?.data[`${typeValue}`]?.unit
          }`}
        </div>
      }
    </div>
  );
};

export default ExtraValueRealtime;
